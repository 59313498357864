.rainbow {
    position: relative;
    width: auto;
    height: auto;
    background: linear-gradient(0deg, #000, #272727);
    border-radius: 16px;
  }
  
  .rainbow:before, .rainbow:after {
    content: '';
    position: absolute;
    left: -2px;
    top: -2px;
    border-radius: 16px;
    background: linear-gradient(45deg, #fb0094, #0000ff, #00ff00,#ffff00, #ff0000, #fb0094, 
      #0000ff, #00ff00,#ffff00, #ff0000);
    background-size: 400%;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    z-index: -1;
    animation: steam 20s linear infinite;
  }
  
  @keyframes steam {
    0% {
      background-position: 0 0;
    }
    50% {
      background-position: 400% 0;
    }
    100% {
      background-position: 0 0;
    }
  }
  
  .rainbow:after {
    filter: blur(50px);
  }

  .game-border {
    border-radius: 16px;
    border: 2px solid white;
  }

  /* .selected-category {
    animation: selected-category-animation 0.5s ease-in-out infinite alternate;
  } */

  @keyframes selected-category-animation {
    0% {
        transform: scale(1.0);
    }
    100% {
        transform: scale(1.05);
    }
}
  .selected-category {
    position: relative;
    margin: auto;
    width: 120px;
    line-height: 64px;
    text-align: center;
    color: #fff;
    font-size: 20px;
    border: 2px solid gold;
    border-radius: 10px;
    background: gold;
    transition: all 1s;
    cursor: pointer;
  }
  .selected-category:hover {
    filter: contrast(1.1);
  }
  .selected-category:active {
    filter: contrast(0.9);
  }
  .selected-category::before, .selected-category::after {
    content: "";
    position: absolute;
    top: 4px;
    left: 4px;
    right: 4px;
    bottom: 4px;
    border: 2px solid rgb(255, 255, 255, 1);
    transition: all 0.5s;
    -webkit-animation: clippath 3s infinite linear;
            animation: clippath 3s infinite linear;
    border-radius: 20px;
  }
  .selected-category::after {
    -webkit-animation: clippath 3s infinite -1.5s linear;
            animation: clippath 3s infinite -1.5s linear;
  }

  @media screen and (max-width: 500px) {
    .selected-category::before, .selected-category::after {
      top: .5vw;
      left: .5vw;
      right: .5vw;
      bottom: .5vw;
      border: 2px solid rgb(255, 255, 255, 1);
      border-radius: 3vw;
    }
  }
  
  @-webkit-keyframes clippath {
    0%, 100% {
      -webkit-clip-path: inset(0 0 98% 0);
              clip-path: inset(0 0 98% 0);
    }
    25% {
      -webkit-clip-path: inset(0 98% 0 0);
              clip-path: inset(0 98% 0 0);
    }
    50% {
      -webkit-clip-path: inset(98% 0 0 0);
              clip-path: inset(98% 0 0 0);
    }
    75% {
      -webkit-clip-path: inset(0 0 0 98%);
              clip-path: inset(0 0 0 98%);
    }
  }
  
  @keyframes clippath {
    0%, 100% {
      -webkit-clip-path: inset(0 0 98% 0);
              clip-path: inset(0 0 98% 0);
    }
    25% {
      -webkit-clip-path: inset(0 98% 0 0);
              clip-path: inset(0 98% 0 0);
    }
    50% {
      -webkit-clip-path: inset(98% 0 0 0);
              clip-path: inset(98% 0 0 0);
    }
    75% {
      -webkit-clip-path: inset(0 0 0 98%);
              clip-path: inset(0 0 0 98%);
    }
  }

  .gradient-border {
    --borderWidth: 3px;
    background: #1D1F20;
    position: relative;
    border-radius: 0;
  }
  .gradient-border:after {
    content: '';
    position: absolute;
    top: calc(-1 * var(--borderWidth));
    left: calc(-1 * var(--borderWidth));
    height: calc(100% + var(--borderWidth) * 2);
    width: calc(100% + var(--borderWidth) * 2);
    background: linear-gradient(60deg, #a37727, #ffffd9, #a37727, #ffffd9, #a37727, #ffffd9, #a37727, #ffffd9);
    border-radius: 0;
    z-index: -1;
    -webkit-animation: animatedgradient 3s ease alternate infinite;
            animation: animatedgradient 3s ease alternate infinite;
    background-size: 300% 300%;
  }
  
  
  @-webkit-keyframes animatedgradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  
  @keyframes animatedgradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }


  /** ----------------- glowing game selection logo ---------------- **/

/* star shaped shine pattern */
.shine {
	position: absolute;
	top: 3px;
	left: 38px;
	width: 1px;
	height: 16px;
	background: #fff;
	transform: rotate(15deg);
	box-shadow: 0 0 8px rgba(255,255,255,0.75);
	animation: shine 4s infinite;
}
.shine:before, .shine:after {
	content:'';
	display: block;
	position: absolute;
}
.shine:before {
	top: 2px;
	left: -2px;
	width: 1px;
	height: 16px;
	background: #fff;
	transform: rotate(-90deg);
	box-shadow: 0 0 8px rgba(255,255,255,0.75);
}
.shine:after {
	top: 6px;
	left: -2px;
	width: 5px;
	height: 5px;
	background: #fff;
	transform: rotate(45deg);
	box-shadow: 0 0 8px 5px rgba(255,255,255,0.75);
}
/* reflective line */
.light {
	position: absolute;
	width: 180px;
	height: 180px;
	border-radius: 100%;
	overflow: hidden;
  z-index: 2;
}
.light:before {
	content:'';
	display: block;
	position: absolute;
	width: 2px;
	height: 180px;
	top: -20%;
	left: 0;
	transform: rotate(45deg);
	background: rgba(251,202,113,0.5);
	animation: sheen2 4s infinite;
}
/* animates orb glow */
@keyframes orb {
	0%,100% {
		box-shadow: 0 0 5px 2px #8FFFCF;
	}
	50% {
		box-shadow: 0 0 24px 4px #8FFFCF;
	}
}
/* rotates shine */
@keyframes shine {
	0% {
		opacity: 0;
	}
	34% {
		opacity: 0;
		transform: rotate(15deg);
	}
	40% {
		opacity: 0.6;
		transform: rotate(620deg);
	}
	48%,100% {
		opacity: 0;
		transform: rotate(635deg);
	}
}
/* moves light reflection */
@keyframes sheen2 {
	0%,100% {
		top: -75%;
		left: 15%;
		opacity: 0;
		width: 2px;
	}
	1% {
		opacity: 1;
	}
	10% {
		width: 24px;
	}
	11%,18% {
		top: 75%;
		left: 50%;
		width: 2px;
		opacity: 0;
	}
	20% {
		top: -75%;
		left: 15%;
		opacity: 0;
		width: 1px;
	}
	21% {
		opacity: 0.75;
	}
	30% {
		width: 6px;
	}
	31%,98% {
		top: 75%;
		left: 50%;
		width: 2px;
		opacity: 0;
	}
}


.vip-logo {
  border-radius: 50%;
  box-shadow: 0px 0px 9px 4px #fbca71;
  animation: glow 1s linear infinite alternate;
  height: 120px;
  width: 120px;
  position: absolute;
  z-index: 1;
  top: 0px;
  left: 0px;
  right: 0;
  bottom: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
}

@keyframes glow{
  to {
    box-shadow: 0px 0px 30px 35px #fbca71;
  }
}

.game-logo {
  animation: logoScale 0.5s ease-in-out infinite alternate;
  -webkit-animation: logoScale 1s ease-in-out infinite alternate;
}

@keyframes logoScale {
  0% {
      transform: scale(1.0);
  }
  100% {
      transform: scale(1.02);
  }
}

@media screen and (max-width: 570px) {
  .game-logo {
    width: 25vw !important;
  }
  .vip-logo {
   height: 20vw;
   width: 20vw;
  }

  .game-card-header > p {
    font-size: 5.6vw !important;
  }

  .game-card-header > button > p {
    font-size: 4.8vw;
  }

  .game-card-header > button > img {
    height: 8.1vw !important;
  }

  .game-card-footer > div > div > div > p,
  .game-card-footer > div > div > p {
    font-size: 4.21vw !important;
  }

  .game-card-footer > div > div > div > img {
    height: 4vw !important;
    width: 4vw !important;
  }

  .game-card-footer > div > div > div > p {
    margin-left: 2.5vw !important;
  }

  .game-card-footer {
    padding: 2.77vw !important;
  }

  .card-logo-container {
    margin-top: -20.8vw;
  }

  .game-card-container {
    margin-right: -12.5vw !important;
  }

  @keyframes glow{
    to {
      box-shadow: 0px 0px 6vw 3vw #fbca71;
    }
  }

  .light {
    width: 25vw !important;
    height: 25vw !important;
  }

  .game-selection-card > p:first-child {
    font-size: 8.85vw;
    margin-top: 5.5vw;
    margin-bottom: 2.8vw;
  }

  .game-selection-subtitle:nth-child(2) {
    font-size: 4.29vw;
    margin-bottom: 11.5vw;
  }

  .custom-tabs > div > div > button {
    /* margin-right: 0px !important; */
    min-width: 60px;
  }
}

:root {
  --diamond-logo-height: 65px;
  --diamond-logo-width: 66px;
}

.diamond-logo-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: var(--diamond-logo-width) !important;
  width: var(--diamond-logo-width) !important;
  margin-right: calc(-0.5 * var(--diamond-logo-width)) !important;
}

.joinnow-button > button > img {
  background: transparent;
  height: calc(var(--diamond-logo-height) / 2) !important;
  width: auto;
}

.diamond-logo-container > img {
  height: var(--diamond-logo-height);
  width: var(--diamond-logo-width);
}

.lotto-card {
  flex-basis: calc((var(--diamond-logo-width) / 2 - 7px) + 75%) !important;
  max-width: 100% !important;
}


.animated-digits {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeOutLeft {
  0% {
     opacity: 1;
     -webkit-transform: translateX(0);
  }
  100% {
     opacity: 0;
     -webkit-transform: translateX(-480px);
  }
}

@keyframes fadeOutLeft {
  0% {
     opacity: 1;
     transform: translateX(0);
  }
  100% {
     opacity: 0;
     transform: translateX(-480px);
  }
}

.fadeOutLeft {
  -webkit-animation-name: fadeOutLeft;
  animation-name: fadeOutLeft;
}

@media screen and (max-width: 400px) {
  .mob-keypads {
    min-width: 50px !important;
  }
}

.keypads {
  display: block !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width: 500px) {
  .button_back {
    height: 3vw !important;
  }  
}

.top-bottom-gradient::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -2;
  margin: -2px;
  /* !importanté */
  border-radius: inherit;
  /* !importanté */
  background: linear-gradient(0deg, red, orange);
}

.top-bottom-gradient-1::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  margin: -2px;
  /* !importanté */
  border-radius: 50%;
  margin-left: -32px;
  /* !importanté */
  background: linear-gradient(0deg, red, orange);
}

.open-draw {
  background: linear-gradient(#333333, #333333) padding-box, linear-gradient(to bottom, #f6b73c, transparent) border-box !important;
}

.active-draw {
  box-shadow: 0px 0px 5px 0px #FFE600 !important;
}

.vip-drawlist-border {
  background: linear-gradient(#1E1E1E, #1E1E1E) padding-box, linear-gradient(to bottom, #f6b73c, white, #f6b73c) border-box !important;
  border: 0.5px solid transparent;
}

.yiki-drawlist-border {
  background: linear-gradient(#1E1E1E, #1E1E1E) padding-box, linear-gradient(to bottom, #747474, white, #565656) border-box !important;
  border: 0.5px solid transparent;
}

.custom-tabs > div > div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-remove-shadow th {
  box-shadow: none !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  padding-right: 0 !important;
  font-family: 'Inter';
  font-weight: 400;
  color: white;
}

.vip-text-stroke {
  -webkit-text-stroke-width: 0.5px;
  -webkit-text-stroke-color: black;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  background-image: -webkit-repeating-linear-gradient(90deg, #FECE47 5%, #CD8900 25%, #FFE600 33%, #DA9000 32%);
  text-shadow: rgba(254,205,71,0.76) 0px 0px 5px;
}

.yiki-text-stroke {
  -webkit-text-stroke-width: 0.5px;
  -webkit-text-stroke-color: black;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  background-image: -webkit-repeating-linear-gradient(90deg, #FFFFFF 5%, #BBB6B6 25%, #B9B9B9 33%, #EBEBEB 32%);
  text-shadow: rgba(255,255,255,0.76) 0px 0px 5px;
}

.gold-brown-gradient {
  background: linear-gradient(rgb(28, 28, 28), rgb(28, 28, 28)) padding-box,linear-gradient(to bottom, #383200, #FFE600, #3C3600) border-box !important;
  border: 1px solid transparent;
  box-shadow: 0px 0px 4px 0px #FF9900;
}

.custom-line-progress {
  height: 25px !important;
  width: 200px !important;
  border-radius: 25px !important;
  background-color: rgb(35, 35, 35) !important;
}

.custom-line-progress > span {
  border-radius: 25px;
  background-color: transparent;
  background: linear-gradient(90deg, #FFC24A, #CD8900);
}

.rules-gradient {
  border-radius: 10px;
  padding: 1rem;
  background: linear-gradient(rgb(10, 10, 10), rgb(10, 10, 10)) padding-box, linear-gradient(to bottom, rgb(27, 20, 8), #DA9000, rgb(27, 20, 8)) border-box !important;
  border: 0.5px solid transparent;
}


/* .home-custom-table > thead {
  background: linear-gradient(rgb(28, 28, 28), rgb(28, 28, 28)) padding-box,linear-gradient(to bottom, #383200, #FFE600, #3C3600) border-box !important;
  border: 1px solid transparent;
  box-shadow: 0px 0px 4px 0px #FF9900;
} */

.home-table-custom > tbody > tr {
  /* background: linear-gradient(rgb(28, 28, 28), rgb(28, 28, 28)) padding-box,linear-gradient(to bottom, #383200, #FFE600, #3C3600) border-box !important;
  border: 1px solid transparent; */
  margin-top: 2px;
  border: 1px solid white;
  
}

@media screen and (max-width: 599px) {
  .custom-line-progress {
    width: 35vw !important;
    height: 4vw !important;
  }

  .winner-animation,
  .left-wing,
  .right-wing {
    max-width: unset;
    width: 250vw !important;
  }

  .win-icon-overlay {
    width: 71vw;
  }
}

@media screen and (min-width: 600px) { 
  .win-icon-overlay {
    width: 22.5vw;
  }
}

.winner-animation {
  position: fixed;
  z-index: 99999;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0; */
  width: 80vw;
  height: fit-content;
  animation: winner-icon-zoomout 100ms ease-in forwards;
  animation-delay: 1s;
}

@keyframes winner-icon-zoomout {
  100% {
    transform: translate(-50%, -50%) scale(.5);
  }
}

/* impact flame animation */
.impact {
  position: fixed;
  visibility: hidden;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* margin: auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0; */
  z-index: 999;
  animation: play-impact-gif 100ms forwards;
  animation-delay: 1.1s;
}

/* show flames on impact animation */
@keyframes play-impact-gif {
  99% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

.left-wing {
  /* width: fit-content; */
  height: fit-content;
  position: fixed;
  /* display: block; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin: auto; */
  width: 80vw;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(.5);
  /* top: 0;
  right: 0;
  left: 0;
  bottom: 0; */
  z-index: 9999;
  animation: left-wing-animation 200ms linear forwards;
  /* animation: left-wing-animation 10000ms linear forwards; */
  animation-delay: 1.05s;
}

.left-wing img {
  width: 100%;
  animation: left-wing-rotate 200ms linear forwards;
  /* animation: left-wing-rotate 10000ms linear forwards; */
  animation-delay: 1.05s;
}

.right-wing {
  /* width: fit-content; */
  height: fit-content;
  position: fixed;
  /* display: block; */
  /* margin: auto; */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80vw;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(.5);
  /* top: 0;
  right: 0;
  left: 0;
  bottom: 0; */
  z-index: 9999;
  animation: right-wing-animation 200ms linear forwards;
  animation-delay: 1.05s;
}

.right-wing img {
  width: 100%;
  animation: right-wing-rotate 200ms linear forwards;
  animation-delay: 1.05s;
}

@keyframes left-wing-animation {
  0% {
    left: 40%;
    top: 50%;
  }
  /* 50% {
    left: 45%;
    top: 50%
  } */
  100% {
    left: 50%;
    top: 50%;
  }
}

@keyframes right-wing-animation {
  0% {
    right: 40%;
    top: 50%;
  }
  /* 50% {
    right: -10%;
    top: -5%
  } */
  100% {
    right: 50%;
    top: 50%;
  }
}

@keyframes left-wing-rotate {
  from {
    transform: rotate(270deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes right-wing-rotate {
  from {
    transform: rotate(90deg);
  }
  to {
    transform: rotate(0deg);
  }
}

/* Hide scrollbars */
.remove-scrollbar {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}
.remove-scrollbar::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

.win-dialog {
  animation: win-dialog-animation 500ms linear forwards;
  animation-delay: 3s;
}

@keyframes win-dialog-animation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.win-animation-container {
  
}

.win-icon-overlay {
  position: fixed;
  z-index: 99999;
  display: block;
  top: 50%;
  left: 50%;
  /* width: 22.5vw; */
  height: fit-content;
}